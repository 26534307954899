<template>
  <div>
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <b-card v-else>
      <validation-observer ref="vpnDialupsCreate">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Name"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required|max:255"
                >
                  <b-form-input
                    v-model="form.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Name"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Client"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Client"
                  vid="client"
                  rules="max:255"
                >
                  <b-form-input
                    v-model="form.client"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Client"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Partner"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Partner"
                  vid="partner"
                  rules="max:255"
                >
                  <b-form-input
                    v-model="form.partner"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Partner"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Tariff"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tariff"
                  vid="tariff_id"
                >
                  <v-select
                    v-model="form.tariff_id"
                    :options="tariffList"
                    :searchable="false"
                    :reduce="tariff => tariff.id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Login"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Login"
                  vid="username"
                  rules="required|max:64"
                >
                  <b-form-input
                    v-model="form.username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Login"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Password"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|max:64"
                >
                  <b-form-input
                    v-model="form.password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Password"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Subnet"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Subnet"
                  vid="subnet_id"
                >
                  <v-select
                    v-model="form.subnet_id"
                    :options="subnetList"
                    :searchable="false"
                    :reduce="subnet => subnet.id"
                    label="name"
                    @input="getIpAddress($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="form.subnet_id"
                label="Assigned IP"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Assigned IP"
                  vid="ip_address"
                  rules="required|max:64"
                >
                  <b-form-input
                    v-model="form.ip_address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Assigned IP"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BSpinner,
} from 'bootstrap-vue'
import { required, max } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import { mapActions, mapState } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
    vSelect,

  },
  data() {
    return {
      loading: false,
      form: {
        name: null,
        username: null,
        password: null,
        client: null,
        partner: null,
        tariff_id: null,
        subnet_id: null,
        ip_address: null,
      },
      required,
      max,
    }
  },
  computed: {
    ...mapState('vpnDialups', {
      tariffList: 'tariffList',
      subnetList: 'subnetList',
      assignedIp: 'assignedIp',
    }),
  },
  async mounted() {
    this.loading = true
    await Promise.all([this.getTariffs(), this.getSubnets()]).finally(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions('vpnDialups', [
      'getTariffs',
      'getSubnets',
      'getAssignedIp',
      'createItem',
    ]),
    async getIpAddress(item) {
      if (item) {
        await this.getAssignedIp(item)
        this.form.ip_address = this.assignedIp
      }
    },
    async validationForm() {
      const valid = await this.$refs.vpnDialupsCreate.validate()
      if (valid) {
        try {
          await this.createItem(this.form)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'dialups-list' })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.vpnDialupsCreate.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
