var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('b-spinner',{staticClass:"position-absolute",staticStyle:{"top":"50%","left":"48%"},attrs:{"variant":"primary","label":"Spinning"}}):_c('b-card',[_c('validation-observer',{ref:"vpnDialupsCreate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Name","vid":"name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Name","autocomplete":"off"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Client","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Client","vid":"client","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Client","autocomplete":"off"},model:{value:(_vm.form.client),callback:function ($$v) {_vm.$set(_vm.form, "client", $$v)},expression:"form.client"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Partner","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Partner","vid":"partner","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Partner","autocomplete":"off"},model:{value:(_vm.form.partner),callback:function ($$v) {_vm.$set(_vm.form, "partner", $$v)},expression:"form.partner"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tariff","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Tariff","vid":"tariff_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.tariffList,"searchable":false,"reduce":function (tariff) { return tariff.id; },"label":"name"},model:{value:(_vm.form.tariff_id),callback:function ($$v) {_vm.$set(_vm.form, "tariff_id", $$v)},expression:"form.tariff_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Login","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Login","vid":"username","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Login","autocomplete":"off"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Password","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Password","autocomplete":"off"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Subnet","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Subnet","vid":"subnet_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.subnetList,"searchable":false,"reduce":function (subnet) { return subnet.id; },"label":"name"},on:{"input":function($event){return _vm.getIpAddress($event)}},model:{value:(_vm.form.subnet_id),callback:function ($$v) {_vm.$set(_vm.form, "subnet_id", $$v)},expression:"form.subnet_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.form.subnet_id)?_c('b-form-group',{attrs:{"label":"Assigned IP","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Assigned IP","vid":"ip_address","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Assigned IP","autocomplete":"off"},model:{value:(_vm.form.ip_address),callback:function ($$v) {_vm.$set(_vm.form, "ip_address", $$v)},expression:"form.ip_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,309875613)})],1):_vm._e()],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }